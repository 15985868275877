// カスタムプロパティ定義
export const setCustomProperties = (container) => {
  // iosのメニューバーを除いたvhの動的取得
  (() => {
    var customViewportCorrectionVariable = "vh";
    function setViewportProperty(doc) {
      var prevClientHeight;
      var customVar = "--" + (customViewportCorrectionVariable || "vh");
      function handleResize() {
        var clientHeight = doc.clientHeight;
        if (clientHeight === prevClientHeight) return;
        requestAnimationFrame(function updateViewportHeight() {
          doc.style.setProperty(customVar, clientHeight * 0.01 + "px");
          prevClientHeight = clientHeight;
        });
      }
      handleResize();
      return handleResize;
    }

    window.addEventListener("resize", setViewportProperty(document.documentElement));
  })();

  // articleの横幅を取得
  (() => {
    const article = container.querySelector("[data-article]");
    if (article == null) return false; // 要素がなければ終了
    let articleWidth;

    function updatePropertyArticleWidth() {
      articleWidth = article.clientWidth;
      document.documentElement.style.setProperty("--article-width", articleWidth + "px");
    }

    updatePropertyArticleWidth();
    window.addEventListener("resize", updatePropertyArticleWidth);
  })();

  // 広告のオーバーレイの高さを設定
  (() => {
    const ad = container.querySelector(".g-ad-overlay");
    if (!ad) return;
    const resizeObserver = new ResizeObserver(() => {
      let adOverlayHeight = ad.clientHeight;
      document.documentElement.style.setProperty("--ad-overlay-height", adOverlayHeight + "px");
      setTimeout(() => {
        if (adOverlayHeight !== ad.clientHeight) {
          adOverlayHeight = ad.clientHeight;
          document.documentElement.style.setProperty("--ad-overlay-height", adOverlayHeight + "px");
        }
      }, 100);
    });
    resizeObserver.observe(ad);
  })();

  // headerロゴの高さを設定
  (() => {
    const header = document.querySelector(".l-header");
    if (!header) return false;

    const getImage = () => header.querySelector(".l-header__image");
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        document.documentElement.style.setProperty("--header-height", getImage() ? header.clientHeight + "px" : null);
      }, 200);
    });

    resizeObserver.observe(header);
    return false;
  })();
};
