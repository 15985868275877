// ration = width or height の数字が小さい方 / width or height の数字が大きい方
const handleImageOnload = (target, ration, handleAddClass) => {
  const handleClass = (target) => {
    const width = target.clientWidth;
    const height = target.clientHeight;
    if (width === 0 || height === 0) return false;

    // 初期化
    target.classList.remove("js-length--square", "js-length--horizontal", "js-length--vertical");

    if (width > height && height / width < ration) {
      handleAddClass("js-length--horizontal");
    } else if (height > width && width / height < ration) {
      handleAddClass("js-length--vertical");
    } else {
      handleAddClass("js-length--square");
    }
    return false;
  };

  const img = new Image();

  img.src = target.src;
  img.onload = () => handleClass(img);

  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => handleClass(entry.target));
  });

  resizeObserver.observe(target);
};

export const checkAspectRatio = (container) => {
  const dataList = container.querySelectorAll("[data-aspect-ratio]");
  const articleImages = container.querySelectorAll("[data-article] > picture > img");
  const targets = Object.setPrototypeOf([...dataList, ...articleImages], NodeList.prototype); //　二つの配列を結合

  targets.forEach((target) => {
    const handleAddClass = (className) => {
      const parent = target.parentNode;

      if (parent && parent.nodeName === "PICTURE") {
        // pictureの子要素に一律classをつける
        const childrenList = target.parentNode.children;

        for (let i = 0; i < childrenList.length; i++) {
          childrenList[i].classList.add(className);
        }
      } else {
        target.classList.add(className);
      }
      return false;
    };

    handleImageOnload(target, 0.85, handleAddClass);
    return false;
  });
};

// headerロゴ用の処理
export const checkHeaderLogoAspectRatio = (container) => {
  const target = container.querySelector(".l-header__image");

  if (!target) return false;
  const handleAddClass = (addClassName) => {
    target.classList.add(addClassName);
    target.classList.add("js-header__image--show");
    return false;
  };

  handleImageOnload(target, 0.75, handleAddClass);
  return false;
};
