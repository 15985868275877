import barba from "@barba/core";
import anime from "animejs/lib/anime.es.js";

// ドロワークラス
const CLASS_HIDE_DRAWER = "l-header__menu--hide";

// bodyスクロール固定関連
const FIXED_BODY_NAME = "js-scroll-fixed";
let fixedBodyScrollPosition;

// body固定
const fixedBody = () => {
  fixedBodyScrollPosition = window.scrollY;
  document.body.classList.add(FIXED_BODY_NAME);
  document.body.style.top = `-${fixedBodyScrollPosition}px`;
  return false;
};

// body固定解除
const unFixedBody = () => {
  document.body.classList.remove(FIXED_BODY_NAME);
  document.body.style.top = 0;
  window.scrollTo(0, fixedBodyScrollPosition);
  return false;
};

// sticky固定
const fixedSticky = (fixedBodyClass) => {
  const $target = document.querySelector(".p-history__sticky");
  if ($target == null) {
    return false;
  }
  const $parent = $target.parentNode;

  $target.removeAttribute("style");
  $parent.removeAttribute("style");

  if (document.body.classList.contains(fixedBodyClass)) {
    return false;
  }

  // 高さを固定するため親への指定が先
  $parent.style.cssText = `width: ${$parent.clientWidth}px; height: ${$parent.clientHeight}px`;
  $target.style.cssText = `position: fixed; top: ${$target.getBoundingClientRect().top}px; width: ${$parent.clientWidth}px; height: ${$parent.clientHeight}px`;
};

// ドロワーオープンアニメーション
const drawerOpenAnimation = () => {
  const $menu = document.querySelector('[data-drawer="menu"]');

  fixedSticky(FIXED_BODY_NAME);
  fixedBody();

  anime
    .timeline({
      delay: 0,
      easing: "easeInOutQuad",
    })
    .add({
      targets: '[data-drawer="open"]',
      duration: 250,
      opacity: [1, 0],
      complete: () => {
        $menu.classList.remove(CLASS_HIDE_DRAWER);
        $menu.scrollTop = 0;
      },
    })
    .add({
      targets: '[data-drawer="menu"]',
      duration: 250,
      opacity: [0, 1],
    })
    .add({
      targets: ".l-header__item",
      duration: 250,
      opacity: [0, 1],
      translateY: [0, "5px"],
      delay: anime.stagger(50),
    });
};

// ドロワークローズアニメーション
const drawerCloseAnimation = (callback, value) => {
  fixedSticky(FIXED_BODY_NAME);
  unFixedBody();

  anime
    .timeline({
      delay: 0,
      duration: 250,
      easing: "easeInOutQuad",
    })
    .add({
      targets: '[data-drawer="menu"]',
      opacity: [1, 0],
      complete: () => {
        document.querySelector('[data-drawer="menu"]').classList.add(CLASS_HIDE_DRAWER);
      },
    })
    .add({
      targets: '[data-drawer="open"]',
      opacity: [0, 1],
      begin: () => {
        if (typeof callback !== "undefined") {
          callback(value);
        }
      },
    });
};

// アンカー移動アニメーション
const anchorTransition = (anchorLink) => {
  const el = document.getElementById(`${anchorLink}`);
  if (!el) return false;
  document.documentElement.scrollTop = el.offsetTop;
  history.replaceState("", "", `${location.pathname}#${anchorLink}`);
};

// ドロワー処理
export const initDrawer = () => {
  const $drawer = document.querySelector('[data-drawer="menu"]');
  const $openButton = document.querySelector('[data-drawer="open"]');
  const $closeButton = document.querySelector('[data-drawer="close"]');

  if(!$drawer) return false

  const pageTransition = ($this) => {
    const url = $this.getAttribute("href");
    const reg = new RegExp("^(https?:)?//" + document.domain);
    const barbaPrevent = $this.classList.contains("js-prevent-barba");

    // 外部リンクに対応する処理 https://q-az.net/javascript-local-links/
    if ((url.match(reg) || url.charAt(0) === "/") && !barbaPrevent) {
      //内部リンク時の処理
      barba.go(url);
    } else {
      //外部リンク時の処理
      location.href = url;
    }
  };

  $openButton.addEventListener("click", drawerOpenAnimation);

  $drawer.addEventListener("click", () => {
    $closeButton.click();
  });

  $closeButton.addEventListener("click", (e) => {
    e.stopPropagation();
    drawerCloseAnimation();
  });

  // リンク処理
  $drawer.querySelectorAll("a").forEach((target) => {
    target.addEventListener("click", (e) => {
      const url = target.getAttribute("href");

      e.preventDefault();
      e.stopPropagation();

      // Topページのみページジャンプ
      if (document.querySelector('[data-barba-namespace="top"]') && url.startsWith("/#")) {
        const splitTargetUrl = url.split("#");
        const ANCHOR_LINK = splitTargetUrl[1];

        history.replaceState("", "", location.pathname);
        drawerCloseAnimation(anchorTransition, ANCHOR_LINK);
        return;
      }

      drawerCloseAnimation(pageTransition, target);
    });
  });

  // header-item[data]が一個のみ
  const drawerItems = ["[data-link-white]", "[data-link-dark]"];

  const handleChangeClass = () => {
    drawerItems.map((drawerItem) => {
      if (!$drawer.querySelectorAll(drawerItem)[0]) return false;

      if (!$drawer.querySelectorAll(drawerItem)[1]) {
        $drawer.querySelectorAll(drawerItem)[0].classList.add("js-header__item--center");
      } else {
        $drawer.querySelectorAll(drawerItem).forEach((item) => item.classList.remove("js-header__item--center"));
      }
    });
  };

  handleChangeClass();

  const config = {
    childList: true,
  };

  const observer = new MutationObserver(() => handleChangeClass());

  observer.observe($drawer.querySelector(".l-header__list"), config);
};
