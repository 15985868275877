import anime from "animejs/lib/anime.es.js";

export const handleHeader = () => {
  const header = document.querySelector('[header="root"]');
  if (!header || document.querySelector(".js-header__intersection")) return;
  const body = header.querySelector('[header="body"]');
  if (!body) return;
  const logo = header.querySelector('[header="logo"]');
  const intersectionEl = document.createElement("div");
  const fixedViewportTopClass = "js-header__body--fixed";

  // アニメーションの共通設定
  const animeOptions = {
    delay: 0,
    duration: 250,
    easing: "easeInOutQuad",
  };

  // ヘッダーをページトップに固定
  const fixedHeaderToPageTop = () => {
    if (!body.classList.contains(fixedViewportTopClass)) return;

    anime({
      ...animeOptions,
      targets: body,
      opacity: [1, 0],
      complete: () => {
        body.classList.remove(fixedViewportTopClass);

        anime({
          ...animeOptions,
          // ロゴがない場合がある
          targets: [body, logo !== null ? logo : ""],
          opacity: [0, 1],
        });
      },
    });
  };

  // ヘッダーをビューポートトップに固定
  const fixedHeaderToViewportTop = () => {
    body.classList.add(fixedViewportTopClass);

    anime({
      ...animeOptions,
      targets: body,
      opacity: [0, 1],
      begin: () => {
        // ロゴがない場合がある
        if (logo !== null) {
          anime({
            ...animeOptions,
            targets: logo,
            opacity: [1, 0],
          });
        }
      },
    });
  };

  let timeoutID;
  const callback = (entries) => {
    entries.forEach((entry) => {
      // 前回のtimeoutを終了（ある場合）
      clearTimeout(timeoutID);
      // 前回のanimationを終了（ある場合）
      anime.remove(body);

      // 動作が連続すると不具合を起こすため遅延させる
      timeoutID = setTimeout(() => {
        if (entry.isIntersecting) {
          fixedHeaderToPageTop();
        } else {
          fixedHeaderToViewportTop();
        }
      }, 100);
    });
  };

  const observerOptions = {
    rootMargin: "0px",
    threshold: 0,
  };

  const observer = new IntersectionObserver(callback, observerOptions);

  intersectionEl.classList.add("js-header__intersection");
  header.appendChild(intersectionEl);
  observer.observe(intersectionEl);
};
