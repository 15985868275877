export const intersectionHeader = (container) => {
  // TODO: optionのrootMarginを動的な値にしたい
  const target = {
    sections: container.querySelectorAll('[data-intersection]'),
    header: document.querySelector('[data-intersection-header]'),
  }

  if (!target.header | !target.sections.length) return false;

  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        target.header.dataset.intersectionHeader = entry.target.dataset.intersection;
        return
      }
    })
  }

  const options = {
    rootMargin: "-5% 0px -95% 0px",
    threshold: 0,
  }

  const intersectionObserver = new IntersectionObserver(callback, options);
  target.sections.forEach((section) => intersectionObserver.observe(section));
}
